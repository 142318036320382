import { SET_TRACKINGS_DATA } from "../constants/ActionTypes";

const INIT_STATE = {
  trackings: [],
  meta: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRACKINGS_DATA: {
      return {
        ...state,
        trackings: action.payload.data,
        meta: action.payload.meta,
      };
    }

    default:
      return state;
  }
};
