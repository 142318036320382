import { SET_LIST_COMPANIES } from "../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  message: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LIST_COMPANIES: {
      return {
        ...state,
        data: action.payload
      };
    }

    default:
      return state;
  }
};
