import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header"></li>
          {/*<li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/dashboard">
                  <span className="nav-text">Business</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/stats">
                  <span className="nav-text">Stat</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/calendar">
                  <span className="nav-text">Calendrier</span>
                </NavLink>
              </li>
            </ul>
          </li>*/}

          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to="/app/stats">
              <span className="nav-text">Stat</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/ordres/all">
              <span className="nav-text">Interventions</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/carte">
              <span className="nav-text">Carte</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/positions">
              <span className="nav-text">Positions</span>
            </NavLink>
          </li>

          {this.props.authUser && this.props.authUser.user_type === 100000 ? (
            <>
              <li className="menu no-arrow">
                <NavLink to="/app/users/orders">
                  <span className="nav-text">Techniciens Inter</span>
                </NavLink>
              </li>
            </>
          ) : (
            <></>
          )}

          {this.props.authUser && this.props.authUser.user_type === 100000 ? (
            <>
              <li className="menu no-arrow">
                <NavLink to="/app/invoices">
                  <span className="nav-text">Factures</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <a
                  target="_blank"
                  href="https://stats.standardenligne.fr/#!/cdrs"
                >
                  <span className="nav-text">Standard en ligne</span>
                </a>
              </li>
            </>
          ) : (
            <></>
          )}
          {/* <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />

                  <NavLink to="/app/ordres/all">
                    <span className="nav-text">Interventions</span>
                  </NavLink>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink to="/app/ordres/today">
                      <span className="nav-text">Du jour</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/app/ordres/progess">
                      <span className="nav-text">En Cours</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/ordres/to-pay">
                      <span className="nav-text">A Payer</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/ordres/waiting-payment">
                      <span className="nav-text">En attente Paiement</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/app/ordres/all">
                      <span className="nav-text">Toutes</span>
                    </NavLink>
                  </li>
                </ul>
              </li> */}

          {this.props.authUser && this.props.authUser.user_type === 100000 ? (
            <>
              {/* <li className="menu no-arrow">
                <NavLink to="/app/crm">
                  <i className="zmdi zmdi-email zmdi-hc-fw" />
                  <span className="nav-text">Crm</span>
                </NavLink>
              </li> */}

              <li className="nav-header">Gestion</li>
              {/*<
              <li className="menu no-arrow">
                <NavLink to="/app/techpaid">
                  <i className="zmdi zmdi-check-square zmdi-hc-fw" />
                  <span className="nav-text">Paye Techniciens</span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to="/app/stpaid">
                  <i className="zmdi zmdi-check-square zmdi-hc-fw" />
                  <span className="nav-text">Techniciens ST</span>
                </NavLink>
              </li>

            li className="menu no-arrow">
                <NavLink to="/app/customers">
                  <i className="zmdi zmdi-email zmdi-hc-fw" />
                  <span className="nav-text">Clients</span>
                </NavLink>
              </li>*/}

              <li className="menu no-arrow">
                <NavLink to="/app/users">
                  <i className="zmdi zmdi-check-square zmdi-hc-fw" />
                  <span className="nav-text">Techniciens</span>
                </NavLink>
              </li>

              <li className="menu no-arrow">
                <NavLink to="/app/campaigns">
                  <i className="zmdi zmdi-check-square zmdi-hc-fw" />
                  <span className="nav-text">Campagnes</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/website-phones">
                  <i className="zmdi zmdi-email zmdi-hc-fw" />
                  <span className="nav-text">Numéros</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/cdrs">
                  <i className="zmdi zmdi-email zmdi-hc-fw" />
                  <span className="nav-text">CDR</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/trackings">
                  <i className="zmdi zmdi-email zmdi-hc-fw" />
                  <span className="nav-text">Tracking</span>
                </NavLink>
              </li>
            </>
          ) : this.props.authUser &&
            this.props.authUser.user_type === 200000 ? (
            <></>
          ) : this.props.authUser &&
            this.props.authUser.user_type === 900000 ? (
            <>
              <li className="nav-header">Gestion</li>
              <li className="menu no-arrow">
                <NavLink to="/app/admin/companies">
                  <i className="zmdi zmdi-email zmdi-hc-fw" />
                  <span className="nav-text">Société</span>
                </NavLink>
              </li>
              <li className="menu no-arrow">
                <NavLink to="/app/admin/users">
                  <i className="zmdi zmdi-email zmdi-hc-fw" />
                  <span className="nav-text">Utilisateurs</span>
                </NavLink>
              </li>
            </>
          ) : null}
        </ul>
      </CustomScrollbars>
    );
  }
}

const Router = withRouter(SidenavContent);

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;

  return { authUser };
};

export default connect(mapStateToProps)(Router);
