import { SET_ORDERS_COUNT, SET_ORDERS_DATA } from "../constants/ActionTypes";

const INIT_STATE = {
  orders: [],
  order: {},
  meta: {},
  title: "",
  ordersConfig: {
    include: "customer,user,company",
    page: 1,
    per_page: 1,
    filters: {},
    sort: {},
  },
  count: {
    all: 0,
    cancel: 0,
    day: 0,
    yesterday: 0,
    inProgress: 0,
    quotation: 0,
    toBill: 0,
    to_pay: 0,
    todispatch: 0,
    toValidate: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ORDERS_DATA: {
      return {
        ...state,
        orders: action.payload.data,
        meta: action.payload.meta,
      };
    }

    case SET_ORDERS_COUNT: {
      return {
        ...state,
        count: action.payload,
      };
    }

    default:
      return state;
  }
};
