import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "actions/Auth";

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        {this.props.authUser ? (
          <>
            <Avatar
              alt="..."
              src={"https://via.placeholder.com/150x150"}
              className="user-avatar "
            />
            <div className="user-detail">
              <h4 className="user-name" onClick={this.handleClick}>
                {this.props.authUser
                  ? this.props.authUser.firstname +
                    " " +
                    this.props.authUser.lastname
                  : null}
                <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
              </h4>
            </div>
            <Menu
              className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              PaperProps={{
                style: {
                  minWidth: 120,
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  this.handleRequestClose();

                  this.props.history.push({
                    pathname: "/app/mon-profil",
                  });
                }}
              >
                <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
                Mon profil
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  this.handleRequestClose();
                  this.props.history.push("/app/campaigns/new");
                }}
              >
                <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
                Ma société
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  this.handleRequestClose();
                  this.props.userSignOut();
                }}
              >
                <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
                Déconnexion
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  const { settings, auth } = props;
  const { locale } = settings;

  const { authUser } = auth;
  return { locale, authUser };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
