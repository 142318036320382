import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { userToken, changePassword } from "actions/Auth";

class RegisterToken extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;

    this.state = {
      password: "",
      token: params ? params.token : null,
      data: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    if (params.token) {
      const data = await this.props.userToken(params.token);
      if (!data) {
        this.props.history.push({
          pathname: "/login",
        });
      }
      this.setState({
        data,
        isLoading: false,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.authUser !== null) {
      this.props.history.push("/app/dashboard");
    }
  }

  render() {
    const { token, password } = this.state;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        {!this.state.isLoading ? (
          <div className="app-login-main-content">
            <div className="app-logo-content d-flex align-items-center justify-content-center">
              <Link className="logo-lg" to="/" title="Jambo">
                <img
                  src={require("assets/images/logo.png")}
                  alt="jambo"
                  title="jambo"
                />
              </Link>
            </div>

            <div className="app-login-content">
              <>
                <div className="app-login-header mb-4">
                  <h1>
                    Bonjour {this.state.data.firstname}, merci de créer votre
                    mot de passe
                  </h1>
                </div>

                <div className="app-login-form">
                  <form>
                    <fieldset>
                      <TextField
                        type="password"
                        label={<IntlMessages id="appModule.password" />}
                        fullWidth
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                        defaultValue={password}
                        margin="normal"
                        className="mt-1 my-sm-3"
                      />

                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <Button
                          onClick={() => {
                            this.props.changePassword({ token, password });
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Enregistrer
                        </Button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </>
            </div>
          </div>
        ) : (
          <InfoView />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { userToken, changePassword })(
  RegisterToken
);
