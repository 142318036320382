import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Companies from "./Companies";
import Users from "./Users";
import Orders from "./Orders";
import Trackings from "./Trackings";
import Cdrs from "./Cdrs";

import WebsitePhones from "./WebsitePhones";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    commonData: Common,
    companies: Companies,
    users: Users,
    orders: Orders,
    websitePhones: WebsitePhones,
    trackings: Trackings,
    cdrs: Cdrs,
  });
