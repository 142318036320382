import { SET_WEBSITE_PHONES } from "../constants/ActionTypes";

const INIT_STATE = {
  phones: [],
  meta: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_WEBSITE_PHONES: {
      console.log(action.payload.meta);
      return {
        ...state,
        phones: action.payload.data,
        meta: action.payload.meta,
      };
    }

    default:
      return state;
  }
};
